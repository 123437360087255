import axios from 'axios';

import { stringify } from 'query-string';
import { Offers } from '../../server/api/mpl/types/matrices.offers';
import { Organization } from '../../services/organizations/types';
import {
    BankCondition,
    IdentsAvailability,
    OfferConfirmation,
    RefinancingOfferSummaryProps,
} from '../../components/marketplace.offers/types';

export class MplProvider {
    constructor(protected path: string) {}

    createDocument = async (packageId: string | number, type: string): Promise<MPL.Document | null> => {
        try {
            const url = `${this.path}/mpl/documents/`;
            const { data } = await axios.post<MPL.Document>(url, { packageId, type });
            return data || null;
        } catch (e) {
            return null;
        }
    };

    deleteDocument = (documentId: string | number) => {
        return axios.delete(`${this.path}/mpl/documents/?documentId=${documentId}`);
    };

    uploadArtifact = async (documentId: string | number, file: File): Promise<MPL.Artifact | null> => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const url = `${this.path}/mpl/artifact/?documentId=${documentId}`;
            const { data } = await axios.post<MPL.Artifact>(url, formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            return data || null;
        } catch (e) {
            return null;
        }
    };

    loadClientData = async (): Promise<MPL.ClientData | null> => {
        try {
            const url = `${this.path}/mpl/client/`;
            const { data } = await axios.get<MPL.ClientData | null>(url);
            return data || null;
        } catch (e) {
            return null;
        }
    };

    loadClientDeal = async (): Promise<MPL.DealItem | null> => {
        try {
            const url = `${this.path}/mpl/client/deal/`;
            const { data } = await axios.get<MPL.DealItem>(url);
            return data || null;
        } catch (e) {
            return null;
        }
    };

    getActiveRequest = async (): Promise<MPL.ActiveRequest | null> => {
        try {
            const url = `${this.path}/mpl/client/active-request/`;
            const { data } = await axios.get<MPL.ActiveRequest | null>(url);
            return data || null;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    registerUser = async (userData: MPL.UserRegisterData): Promise<MPL.ClientData | null> => {
        try {
            const url = `${this.path}/mpl/register/`;
            const { data } = await axios.post<MPL.ClientData | null>(url, userData);
            return data || null;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    async getUnsignedDocuments() {
        try {
            const url = `${this.path}/mpl/client/get-unsigned-docs/`;
            const { data } = await axios.get<{ docs: Array<MPL.UnsignedDocument> }>(url);

            return data;
        } catch (e) {
            console.error(e);

            return {
                docs: [],
            };
        }
    }

    async checkUnsignedDocuments(mplOnly: boolean) {
        try {
            const url = `${this.path}/mpl/client/check-unsigned-docs/?mplOnly=${mplOnly}`;
            const { data } = await axios.get<{ docs: Array<MPL.UnsignedDocument> }>(url);

            return data;
        } catch (e) {
            console.error(e);

            return {
                docs: [],
            };
        }
    }

    async getSignedConsentLinks() {
        try {
            const url = `${this.path}/mpl/client/signed-consent-links/`;
            const { data } = await axios.get<{ docs: Array<MPL.SignedDocument> }>(url);

            return data;
        } catch (e) {
            console.error(e);

            return {
                docs: [],
            };
        }
    }

    async signConsentDocuments(userData: {
        signType: string;
        docs: Array<{ type: string; version: number }>;
        signData?: Record<string, any>;
    }) {
        try {
            const url = `${this.path}/mpl/client/sign-consent-docs/`;
            const { data } = await axios.post(url, userData);

            return data;
        } catch (e) {
            console.error(e);
        }
    }

    async getMatricesOffers(params: Offers.GetParams, signal?: AbortSignal) {
        const url = `${this.path}/mpl/matrices/offers/`;
        const { data } = await axios.get(url, {
            params,
            signal,
        });

        return data;
    }

    async getMatricesInitials(params: Offers.GetParams, signal?: AbortSignal) {
        const url = `${this.path}/mpl/matrices/initials/`;
        const { data } = await axios.get(url, {
            params,
            signal,
        });

        return data;
    }

    async getDealCounters() {
        try {
            const url = `${this.path}/mpl/deal/counters/`;
            const { data } = await axios.get<MPL.DealCounters>(url);

            return data;
        } catch (e) {
            console.error(e);
        }
    }

    async getOrganizationInfoById(bankId: number) {
        try {
            const url = `${this.path}/organizations/info/`;
            const { data } = await axios.get<Organization & { welcomePack: MPL.WelcomePack }>(url, {
                params: { bankId },
            });

            return data;
        } catch (e) {
            console.error(e);
        }
    }

    async getActiveMeeting() {
        try {
            const url = `${this.path}/mpl/meetings/active/`;
            const { data } = await axios.get(url);

            return data;
        } catch (e) {
            console.error(e);
        }
    }

    async setOffer(offer: OfferConfirmation) {
        try {
            const url = `${this.path}/mpl/client/offer/`;
            const { data } = await axios.post(url, { offer });

            return data;
        } catch (e) {
            console.error(e);
        }
    }

    async getBankConditions(bankId: number) {
        const url = `${this.path}/mpl/common/bank-conditions/?bankId=${bankId}`;

        const { data } = await axios.get<BankCondition>(url);

        return data;
    }

    async getRefinOffer(params: Omit<RefinancingOfferSummaryProps, 'sravniruClientId'>) {
        const url = `${this.path}/mpl/matrices/refin/`;
        const { data } = await axios.get(`${url}?${stringify(params)}`);

        return data;
    }

    async getIdentsAvailability() {
        const url = `${this.path}/mpl/idents/identification/availability/`;
        const { data } = await axios.get<IdentsAvailability>(url);

        return data;
    }
}
