import axios from 'axios';
import { TaskData, UserData } from '../../services/amazingScoring/amazingScoring.types';

export type ArrangeItem = {
    probability: number;
    gini?: number;
};

export type PostTaskResponse = {
    task: TaskData;
    /** Record<OrganizationId, Probability> */
    arrange?: Record<string, ArrangeItem>;
};

export type UserDataParams = Omit<UserData, 'userId'>;

export class ArrangeProvider {
    constructor(protected path: string) {}

    get prefix() {
        return `${this.path}/arrange`;
    }

    public async postTask(userData: UserDataParams, userId?: string) {
        const { data } = await axios.post<PostTaskResponse>(
            `${this.prefix}/task/create/`,
            { userData },
            { params: { userId } },
        );
        return data;
    }

    public async checkTask(id: string) {
        const { data } = await axios.get<PostTaskResponse>(`${this.prefix}/task/`, { params: { id } });
        return data;
    }
}
