import axios from 'axios';
import { Review, ReviewsRequestParams } from '../../services/reviews/reviews.types';
import { IBranches, IFetchBranchesParams } from '../../services/gateway/gateway.types';

export class VitrinsProvider {
    constructor(protected path: string) {}

    loadReviews = async (organizationId?: string, params?: ReviewsRequestParams): Promise<Review[]> => {
        try {
            const url = `${this.path}/vitrins/reviews/`;
            const { data } = await axios.post<Review[]>(url, {
                ...params,
                organizationId,
            });
            return data;
        } catch (e) {
            return [];
        }
    };

    loadBranches = async (params: IFetchBranchesParams): Promise<IBranches> => {
        try {
            const url = `${this.path}/vitrins/branches/`;
            const { data } = await axios.post<IBranches>(url, {
                ...params,
            });
            return data;
        } catch (e) {
            return { items: [], totalCount: 0 };
        }
    };
}
