/** @deprecated use css-vars */
export const color = {
    green: 'var(--color-G100)',
    grey: 'var(--color-D50)',
    greyLight: 'var(--color-D20)',
    greyDark: 'var(--color-D60)',
    greyDarkNew: 'var(--color-D80)',
};

export const width = {
    medium: '1340px',
    narrow: '1140px',
    tablet: '1023px',
    mobile: '767px',
};

export const widthForMobileFirst = {
    mobile: '0px',
    tablet: '768px',
    desktop: '1024px',
    desktopLong: '1340px',
};

export const fonts = {
    main: '"Open Sans", sans-serif',
    ubuntu: '"Ubuntu", sans-serif',
    currency: '"PT Sans", sans-serif',
};
