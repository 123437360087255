import axios from 'axios';
import routes from '../../constants/routes.internal.json';
import { clientAxiosInterceptorOnFulfilled } from './axios/interceptor';
import { ClientProvider } from './provider';
import { FbProvider } from './fb';
import { ProfileProvider } from './profile';
import { OffersProvider } from './offers';
import { ApiProvider } from './api';
import { MplProvider } from './mpl';
import { MTSProvider } from './mts';
import { VitrinsProvider } from './vitrins';
import { ArrangeProvider } from './arrange';

axios.interceptors.request.use(clientAxiosInterceptorOnFulfilled);

export const provider = new ClientProvider(routes.provider);
export const fb = new FbProvider(routes.provider);
export const profile = new ProfileProvider(routes.provider);
export const offers = new OffersProvider(routes.provider);
export const api = new ApiProvider(routes.provider);
export const mpl = new MplProvider(routes.provider);
export const mts = new MTSProvider(routes.provider);
export const vitrins = new VitrinsProvider(routes.provider);
export const arrange = new ArrangeProvider(routes.provider);
