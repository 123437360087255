import axios from 'axios';
import { LastOffer } from '../../services/offerSearch/offerSearch.types';
import { Suitable } from '../../services/offers/offers.types';

type PreApproveResponse = {
    cards: {
        exact: Array<LastOffer>;
        suitable: Array<Suitable>;
    };
    credits: {
        exact: Array<LastOffer>;
        suitable: Array<Suitable>;
    };
};

type loadDecisionOffersParams = {
    cardIds?: Array<string>;
    creditIds?: Array<string>;
};

type DecisionOffersResponse = {
    cards: Array<Suitable>;
    credits: Array<Suitable>;
};

export class MTSProvider {
    constructor(protected path: string) {}

    loadDelivery = async (params: Record<string, string | undefined>) => {
        try {
            const url = `${this.path}/mts/delivery/`;
            const { data } = await axios.get<{ dates: Array<string>; intervals: Array<string> }>(url, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                params,
            });
            return data;
        } catch (e) {
            console.log(e);
            return null;
        }
    };

    loadPreApproveOffers = async (phone: string): Promise<PreApproveResponse> => {
        try {
            const formattedPhone = `7${phone.replace(/\D/g, '').slice(-10)}`;
            const url = `${this.path}/mts/preApprove/`;
            const { data } = await axios.get<PreApproveResponse>(url, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                params: {
                    phone: formattedPhone,
                },
            });
            return data;
        } catch (e) {
            console.log(e);
            return {
                cards: {
                    exact: [],
                    suitable: [],
                },
                credits: {
                    exact: [],
                    suitable: [],
                },
            };
        }
    };

    loadDecisionOffers = async (params: loadDecisionOffersParams): Promise<DecisionOffersResponse> => {
        const { creditIds = [], cardIds = [] } = params;
        try {
            const url = `${this.path}/mts/decisionOffers/`;
            const { data } = await axios.get<DecisionOffersResponse>(url, {
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
                params: {
                    creditIds: creditIds.join(','),
                    cardIds: cardIds.join(','),
                },
            });
            return data;
        } catch (e) {
            console.log(e);
            return {
                cards: [],
                credits: [],
            };
        }
    };
}
