import axios from 'axios';

export class ProfileProvider {
    constructor(protected path: string) {}

    update = async (data: FE.FormData, userId?: string | number) => {
        try {
            const { data: result } = await axios.post<boolean>(
                `${this.path}/profile/update/${userId ? `?userId=${userId}` : ''}`,
                data,
            );
            return result;
        } catch (e) {
            return false;
        }
    };

    load = async () => {
        try {
            const { data } = await axios.get<FE.FormData>(`${this.path}/profile/load/`, {
                withCredentials: true,
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
            });
            return data;
        } catch (e) {
            return {};
        }
    };

    saveOld = (data: FE.FormData, userId?: string | number) => {
        return axios.post(`${this.path}/profile/`, { ...data, userId });
    };

    loadOld = async () => {
        try {
            const { data } = await axios.get<FE.FormData>(`${this.path}/profile/`, {
                withCredentials: true,
                headers: { 'X-Requested-With': 'XMLHttpRequest' },
            });
            return data;
        } catch (e) {
            return {};
        }
    };

    /** fillFormWithAuthProfileData removed in CF-979 */
}
