import axios from 'axios';
import { IUnitCodeSuggest } from '../../components/form/ui/suggest/unit.code';
import { SuggestPartyParams, AddressSuggestBound } from '../../services/dadata/types';
import { IssuedParams, UnitCodeResponse } from '../../services/passport/types';

export class ApiProvider {
    url: string;

    constructor(protected path: string) {
        this.url = `${path}/gw`;
    }

    nameSuggest = async (startWith: string, gender?: string) => {
        try {
            const { data } = await axios.post<Array<Dadata.NameSuggest>>(`${this.url}/names/`, {
                payload: { startWith, gender },
            });
            return data;
        } catch (e) {
            return [] as Array<Dadata.NameSuggest>;
        }
    };

    surnameSuggest = async (startWith: string, gender?: string) => {
        try {
            const { data } = await axios.post<Array<Dadata.NameSuggest>>(`${this.url}/surnames/`, {
                payload: { startWith, gender },
            });
            return data;
        } catch (e) {
            return [] as Array<Dadata.NameSuggest>;
        }
    };

    patronymicSuggest = async (startWith: string, gender?: string) => {
        try {
            const { data } = await axios.post<Array<Dadata.NameSuggest>>(`${this.url}/patronymics/`, {
                payload: { startWith, gender },
            });
            return data;
        } catch (e) {
            return [] as Array<Dadata.NameSuggest>;
        }
    };

    fullNameSuggest = async (query: string): Promise<Dadata.FullNameSuggest[]> => {
        try {
            const { data } = await axios.post<Array<Dadata.FullNameSuggest>>(`${this.url}/full-name/`, {
                payload: { query },
            });
            return data;
        } catch (e) {
            return [] as Array<Dadata.FullNameSuggest>;
        }
    };

    addressSuggest = async (startWith: string, toBound?: AddressSuggestBound, count?: number) => {
        try {
            const { data } = await axios.post<Array<Dadata.AddressSuggestItem>>(`${this.url}/address/`, {
                payload: {
                    query: startWith,
                    count,
                    ...(toBound ? { to_bound: { value: toBound } } : {}),
                },
            });
            return data;
        } catch (e) {
            return [] as Array<Dadata.AddressSuggestItem>;
        }
    };

    addressClean = async (address: string) => {
        try {
            const { data } = await axios.post<Dadata.AddressData>(`${this.url}/address-clean/`, {
                payload: address,
            });
            return data;
        } catch (e) {
            return null;
        }
    };

    issued = async (payload: IssuedParams): Promise<string> => {
        try {
            const { data } = await axios.post<string>(`${this.url}/issued/`, {
                payload,
            });
            return data;
        } catch (e) {
            return '';
        }
    };

    unitCodeSuggest = async (q: string, limit = 10): Promise<IUnitCodeSuggest[]> => {
        try {
            const { data } = await axios.post<UnitCodeResponse>(`${this.url}/unit-code/`, {
                payload: { q, limit },
            });
            return data.results;
        } catch (e) {
            return [] as IUnitCodeSuggest[];
        }
    };

    organization = async (
        count = 5,
        query: string,
        parameters?: { locations_boost: SuggestPartyParams['locations_boost'] },
    ) => {
        try {
            const locations_boost = parameters?.locations_boost || undefined;
            const { data } = await axios.post<Array<Dadata.OrganizationSuggestItem>>(`${this.url}/organizations/`, {
                payload: { count, query, locations_boost },
            });
            return data;
        } catch (e) {
            return [] as Array<Dadata.OrganizationSuggestItem>;
        }
    };

    organizationByInn = async (query: string | number) => {
        try {
            const { data } = await axios.post<Dadata.OrganizationSuggestItem | null>(
                `${this.url}/organization-by-inn/`,
                {
                    payload: { query },
                },
            );
            return data;
        } catch (e) {
            return null;
        }
    };

    checkPassport = async (passport: string): Promise<boolean> => {
        try {
            const { data } = await axios.post<boolean>(`${this.url}/valid-passport/`, {
                payload: [passport],
            });
            return data;
        } catch (e) {
            return true;
        }
    };
}
