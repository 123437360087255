import { InternalAxiosRequestConfig } from 'axios';
import { HEADERS, UTM_MARKS } from '../../../constants';
import { randomString } from '../../random';
import { getStore } from '../../../store/client';
import { getUtmAndPageExperiments, wrapStatistics } from '../../experiments';
import { marksSelector } from '../../../store/reducers/utmMarks';

const sessionId = randomString();

export const clientAxiosInterceptorOnFulfilled = (config: InternalAxiosRequestConfig) => {
    const { userId, page, pageContext } = getStore()?.getState().formStore || {};
    const { experiments } = getStore()?.getState().abData || {};
    const marks = getStore().getState().utmMarks || {};
    const { [UTM_MARKS.MEDIUM]: utmMedium } = marksSelector(marks);

    config.headers[HEADERS.SESSION_ID] = sessionId;
    config.headers[HEADERS.TRACE_ID] = randomString();
    config.headers[HEADERS.PAGE_CONTEXT] = [page || undefined, pageContext].filter((v) => v).join('.');
    config.headers[HEADERS.OWNER_ID] = userId || undefined;
    config.headers[HEADERS.AB_GROUPS] =
        wrapStatistics(getUtmAndPageExperiments(experiments, page, utmMedium)) || undefined;
    return config;
};
